import React from "react";
import { useEffect } from "react";
import Product from "../../services/Api/clien";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./InfoProduct.css";
import { TailSpin } from "react-loader-spinner";
import { FaDownload } from "react-icons/fa";
import ProductImagesSlider from "./components";
import { LazyLoadImage } from "react-lazy-load-image-component";


export default function InfoProduct() {
  const [info, setInfo] = useState({});
  const params = useParams();

  useEffect(() => {
    Product.getProducts(params.id)
      .then((res) => {
        setInfo({ ...res.data });
      })
      .catch((err) => {
      });
  }, [params]);

  return (
    <div className="container">
      <div className="row infoPage d-flex">
        {info ? (
          <>
            <div className="text-center">
              <h1 className="headerTitle pb-3">{info.name}</h1>
            </div>
            <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6">
              <div className="infoCard">
                <img
                  data-bs-toggle="modal"
                  href="#exampleModalToggle"
                  role="button"
                  src={info.posterdekor}
                  className="infoImg"
                  alt=""
                />
                <div className="d-flex mt-2">
                  <a
                    href={info.posterdekor}
                    className="downloadIcon"
                    download={info.name}
                    target="_blank"
                  >
                    <FaDownload className="fs-3" />
                  </a>
                  <h5
                    className="mt-2 ms-2 textCompany"
                  >
                    Скачать изображение
                  </h5>
                </div>
              </div>
              <div className="my-2">
                <table>
                  <tbody>
                    <tr>
                      <th className="pe-5 textCompany">Страна</th>
                      <th className="textCompany">{info.statename}</th>
                    </tr>
                    <tr>
                      <th className="pe-5 textCompany">Производитель</th>
                      <th className="textCompany">{info.factoryname}</th>
                    </tr>
                    <tr>
                      <th className="pe-5 textCompany">Размер</th>
                      <th className="textCompany">{info.size}</th>
                    </tr>
                    <tr>
                      <th className="pe-5 textCompany">Материал</th>
                      <th className="textCompany">{info.material}</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="modal fade"
                id="exampleModalToggle"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel"
                tabindex="-1"
              >
                <div class="modal-dialog modal-dialog-centered infoZoom">
                  <LazyLoadImage
                    className="infoZoomImg"
                    src={info.posterdekor}
                    effect="blur"
                    alt="Image Alt"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-xl-6 col-lg-6">
              <div>
                <ProductImagesSlider sliderData={info.posterinfo} />
              </div>
            </div>
            <div className="col-12 my-2">
              <h4 className="aboutProduct">Описание Товара</h4>
              <h5
                className="textCompany"
                dangerouslySetInnerHTML={{ __html: `<h5>${info.info}</h5>` }}
              ></h5>
            </div>
          </>
        ) : (
          <>
            <TailSpin
              height="80"
              width="80"
              color="#FCC12C"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </>
        )}
      </div>
    </div>
  );
}
