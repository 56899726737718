import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Product from "../../services/Api/clien";
import "./Products.css";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import searchImg from "..//..//img/searchImg.jpg";
import axios from "axios";
import Slide from "react-reveal/Slide";

export default function Products() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productBrend, setproductBrend] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    Product.Products(
      `/products/filter/?search=${params.category}`,
      params.id,
      "GET",
      navigate
    ).then((res) => {
      const sortdata = res.data.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      setData([...sortdata]);
      setIsLoading(true);
    });

    Product.getCategories()
      .then((res) => {
        setCategories([...res.data]);
      })
      .catch((err) => {});

    Product.getProductsBrend()
      .then((res) => {
        setproductBrend([...res.data]);
        console.log(res);
      })
      .catch((err) => {});

    switch (params.category) {
      case "Керамогранит":
        setTitle("Керамогранит");
        break;
      case "Керамика":
        setTitle("Керамика");
        break;
      case "Мозаика":
        setTitle("Мозаика");
        break;
      case "Смесители":
        setTitle("Смесители");
        break;
      case "Широкоформатные 3,20x1,60 м":
        setTitle("Широкоформатные 3,20x1,60 м");
        break;
      case "Индия":
        setTitle("Индия");
        break;
      case "Vidrepur":
        setTitle("Vidrepur");
        break;
      case "New ker":
        setTitle("Newker");
        break;
      case "Grespaniya":
        setTitle("Grespaniya");
        break;
      case "Pameca":
        setTitle("Pameca");
        break;
      case "Novogres":
        setTitle("Novogres");
        break;
      case "Arcana":
        setTitle("Arcana");
        break;
      case "Aparici":
        setTitle("Aparici");
        break;
      case "BIGSLABS":
        setTitle("BIGSLABS");
        break;
      case "Keraben":
        setTitle("Keraben");
        break;
      case "Ibero":
        setTitle("Ibero");
        break;
      case "Monopole":
        setTitle("Monopole");
        break;
      case "Metropol":
        setTitle("Metropol");
        break;
      case "Bestile Ceramica":
        setTitle("Bestile Ceramica");
        break;
      case "Plaza Ceramica":
        setTitle("Plaza Ceramica");
        break;

      default:
        setTitle("Поиск");
        break;
    }
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <Slide bottom>
      <div className="d-flex mt-5 pt-3 ">
        <div className=" h-100 productpageCategory mt-5 pt-3">
          {categories.map((arr, id) => {
            return (
              <Link to={`/products/${arr.name}`}>
                <div className="categoyDiv">
                  <h4 key={id} className="categoryText textCategrory">
                    {arr.name}
                  </h4>
                </div>
              </Link>
            );
          })}
          {productBrend.length > 0 ? (
            <>
              <h2 className="brendTitle pt-2">Бренды</h2>
              {productBrend.map((arr, id) => {
                return (
                  <Link to={`/products/${arr.brandname}`}>
                    <div className=" categoyDiv">
                      <h4 key={id} className="categoryText textCategrory">
                        {arr.brandname}
                      </h4>
                    </div>
                  </Link>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="productpage my-5 py-5 w-100">
          <div className="container productes">
            <div className="d-flex flex-column align-content-center">
              <h1 className="text-center searchInfo pb-4 headerTitle">
                {title}
              </h1>
              <div className="row">
                {isLoading ? (
                  <>
                    {data.length > 0 ? (
                      <>
                        {data.map((arr, id) => {
                          return (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                              <Link to={`/infoProduct/${arr.id}`}>
                                <div>
                                  <div className="box">
                                    <LazyLoadImage
                                      className="productImage"
                                      src={arr.posterdekor}
                                      effect="blur"
                                      alt="Image Alt"
                                    />
                                  </div>
                                  <div className="pt-1">
                                    <h4 className="categoryText">{arr.name}</h4>
                                    <p className="categoryText">
                                      {arr.statename}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="col-6 notProduct">
                        <img
                          src={searchImg}
                          alt=""
                          className="SearchImageNotDate text-center"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#FCC12C"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
}
