import { Carousel } from "antd";

const ProductImagesSlider = ({ sliderData = [] }) => {
    const contentStyle = {
        width: "100%",
        color: "#fff",
        lineHeight: "260px",
        textAlign: "center",
      };

    

    

    return (
        <div className="mt-0">
            <Carousel 
            autoplay
            slidesToShow={1}
            dotPosition="top"
            >
                {sliderData.length > 0 && sliderData?.map((arr, index) => {
                    return (
                        <div className="caruselInfo" key={index}>
                            <img
                                className="infoCaruselImg"
                                style={contentStyle}
                                src={arr.image}
                                alt=""
                            />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    )
} 

export default ProductImagesSlider;