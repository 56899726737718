import React from 'react';
import './Located.css'
import Magazin from '..//../img/magazin.jpg'
import Newshop from "..//../img/newshop.jpg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import shopStreet from "..//../img/shopStreet.jpg";
import Slide from "react-reveal/Slide";



export default function Located() {
  return (
    <Slide bottom>
      <div style={{ position: "relative", top: "-140px" }} id="located"></div>
      <div className="text-center bg-light locatedbg py-5">
        <h1 className="fs-1 headerTitle">Наши адреса</h1>
        <div className="container">
          <div className="row pt-5">
            <span className="me-1 fs-3 pb-5 nameLocated">
              Шоурум:
              <a
                className="ms-3 locatedA"
                href="https://www.google.com/maps/place/Mirlar.uz+%D0%9A%D0%B0%D1%84%D0%B5%D0%BB%D1%8C+%D0%9A%D0%B5%D1%80%D0%B0%D0%BC%D0%BE%D0%B3%D1%80%D0%B0%D0%BD%D0%B8%D1%82+%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9+%D0%9C%D0%B0%D0%B9%D0%BE%D0%BB%D0%B8%D0%BA%D0%B0+%D0%9C%D0%BE%D0%B7%D0%B0%D0%B8%D0%BA%D0%B0+%D0%B4%D0%BB%D1%8F+%D0%B1%D0%B0%D1%81%D1%81%D0%B5%D0%B9%D0%BD%D0%B0/@41.2781781,69.2475112,17z/data=!3m1!4b1!4m6!3m5!1s0x38ae8be871b6d64f:0xbbfb36ac2aadd1ec!8m2!3d41.2781781!4d69.2475112!16s%2Fg%2F11ry4x2gy9"
                target="_blank"
              >
                г. Ташкент, Яккасарайский район. 1- проезд Мукимий, Дом-19 А
              </a>
            </span>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 pb-5">
              <div>
                <LazyLoadImage
                  className="w-100 rounded"
                  src={shopStreet}
                  effect="blur"
                  alt="Image Alt"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 pb-5">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.410110932847!2d69.24532251542239!3d41.278178079274326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8be871b6d64f%3A0xbbfb36ac2aadd1ec!2zTWlybGFyLnV6INCa0LDRhNC10LvRjCDQmtC10YDQsNC80L7Qs9GA0LDQvdC40YIg0JjRgdC_0LDQvdGB0LrQuNC5INCc0LDQudC-0LvQuNC60LAg0JzQvtC30LDQuNC60LAg0LTQu9GPINCx0LDRgdGB0LXQudC90LA!5e0!3m2!1sru!2s!4v1678741437277!5m2!1sru!2s"
                  title="W3Schools Free Online Web Tutorials"
                  referrerpolicy="no-referrer-when-downgrade"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row ">
            <span className="me-1 fs-3 pb-5 nameLocated">
              Магазин:
              <a
                className="ms-3 locatedA"
                target="_blank"
                href="https://www.google.com/maps/place/Mirlar.uz+%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9+%D0%BA%D0%B0%D1%84%D0%B5%D0%BB%D1%8C+%D0%B8+%D0%BC%D0%B0%D0%B9%D0%BE%D0%BB%D0%B8%D0%BA%D0%B0/@41.3554458,69.2446614,18.91z/data=!4m14!1m7!3m6!1s0x38ae8c91885e60e1:0xb1b3fdf4f28efcba!2sChuqursoy!8m2!3d41.3548681!4d69.2541159!16s%2Fg%2F11pxx5_yz9!3m5!1s0x38ae8d8fb18ea97d:0xba44ce54790e486c!8m2!3d41.3550035!4d69.2447158!16s%2Fg%2F11ry4zmk33"
              >
                г. Ташкент, Ул. Уста Ширин 125, Магазин № 4/1
              </a>
            </span>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 pb-5 infoLocated">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d798.9834379157293!2d69.2446614051859!3d41.35544580902627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8d8fb18ea97d%3A0xba44ce54790e486c!2zTWlybGFyLnV6INCY0YHQv9Cw0L3RgdC60LjQuSDQutCw0YTQtdC70Ywg0Lgg0LzQsNC50L7Qu9C40LrQsA!5e0!3m2!1sru!2s!4v1678742244571!5m2!1sru!2s"
                  title="W3Schools Free Online Web Tutorials"
                  referrerpolicy="no-referrer-when-downgrade"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 pb-5 ">
              <div>
                <LazyLoadImage
                  className="w-100 rounded newshop infoLocated"
                  src={Magazin}
                  effect="blur"
                  alt="Image Alt"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <span className="me-1 fs-3 pb-5 nameLocated">
              Шоурум:
              <a
                className="ms-3 locatedA"
                target="_blank"
                href="https://www.google.com/maps/dir/41.355658,69.2461875/Mirlar.uz+%D0%9A%D0%B0%D1%84%D0%B5%D0%BB%D1%8C+%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9+%D0%A3%D0%BB.+%D0%A3%D1%81%D1%82%D0%B0+%D0%A8%D0%B8%D1%80%D0%B8%D0%BD+134,+%D0%9C%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD+%E2%84%96+2-111+100019,+Tashkent+Tashkent+Region/@41.3556224,69.2461686,18.5z/data=!4m8!4m7!1m0!1m5!1m1!1s0x38ae8de29cf0e0f5:0x93529aec59c3bae8!2m2!1d69.2461827!2d41.3556501?entry=ttu"
              >
                г. Ташкент, Ул. Уста Ширин 134, Магазин № 2-111
              </a>
            </span>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 pb-5">
              <div>
                <img
                  src={Newshop}
                  alt=""
                  effect="blur"
                  className="rounded w-100 newshop"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 pb-5">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1058.8483562503288!2d69.24607646145488!3d41.35506166140977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8de29cf0e0f5%3A0x93529aec59c3bae8!2zTWlybGFyLnV6INCa0LDRhNC10LvRjCDQmNGB0L_QsNC90YHQutC40Lk!5e0!3m2!1sru!2s!4v1706602736187!5m2!1sru!2s"
                  title="W3Schools Free Online Web Tutorials"
                  referrerpolicy="no-referrer-when-downgrade"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
}
