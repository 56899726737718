import axios from "axios";
import { useNavigate , useParams} from 'react-router-dom'


const client = axios.create({
    baseURL: "https://backmir.mirlar.uz/",
    headers: "accept: application/json",
})


class Product {
    Products(link, method = "GET" , navigate) {
        let result = client({
            method:method,
            url:link,
        })
            .then((res) => {
                return res
            })
            .catch((err) => {
                // if (err.response.status === 400) navigate("/")
                // if (err.response.status === 401) navigate("/navigate")
                return err
            });

        return result
    }

    async getCategories() {
        let response = await client.get("products/category")
            .then((res) => { return res; })
            .catch((err) => { return err })
        return response
    }

    async getProductsBrend() {
        let response = await client.get("brand")
            .then((res) => { return res; })
            .catch((err) => { return err })
        return response
    }


    async getProducts(id) {
        let response = await client.get(`products/${id}`)
            .then((res) => { return res; })
            .catch((err) => { return err })
        return response
    }
}

export default new Product
