import React from 'react'
import "./UseButton.css"
import { FaTelegramPlane } from 'react-icons/fa';
import { AiOutlinePhone } from 'react-icons/ai';

export default function UseButton() {
  return (
    <div>
      <div>
        <a className="toPhone" href="tel:+998909655868">
          <AiOutlinePhone classID="iconFixed" />
        </a>
      </div>
    </div>
  );
}
