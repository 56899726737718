import React, { useEffect, useRef, useState } from "react";
import Logo from "..//../img/logo6.png";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

import "./Navbar.css";
import {
  BsInstagram,
  BsFillTelephoneFill,
  BsClock,
  BsSearch,
} from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { RiMenuFoldFill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import Product from "..//../services/Api/clien";

export default function Navbar() {
  const [togleMenu, settogleMenu] = useState(true);
  const [search, setSearch] = useState(null);
  const navigate = useNavigate();
  const navbar = useRef();
  const drop = useRef();
  const dropTwo = useRef();

  const [openDrop, setOpenDrop] = useState(true);
  const [openDropTwo, setopenDropTwo] = useState(true);

  const [categories, setCategories] = useState([]);
  const [productBrend, setproductBrend] = useState([]);

  function putBtn() {
    settogleMenu((prev) => !prev);
    navbar.current.classList.toggle("lefthide");
  }

  function dropMenu() {
    setOpenDrop((prev) => !prev);
    drop.current.classList.toggle("dropBlock");
  }

  function dropMenuTwo() {
    setopenDropTwo((prev) => !prev);
    dropTwo.current.classList.toggle("dropBlock");
  }

  function getShortText(text) {
    if (text.length > 12) return text.slice(0, 13) + "...";
    else return text;
  }

  useEffect(() => {
    Product.getCategories()
      .then((res) => {
        setCategories([...res.data]);
      })
      .catch((err) => {});

    Product.getProductsBrend()
      .then((res) => {
        setproductBrend([...res.data]);
        console.log(res);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="navbarmain">
      <div className="d-flex  navbarbg align-content-center justify-content-evenly py-1">
        <div className="d-flex clocknumber justify-content-between">
          <b className="d-flex align-contend-center fw-bold fs-4 pe-5 telephone clock">
            <BsClock className="mt-1 me-1" />
            09:30 - 19:00
          </b>
          <a
            href="tel:+998909655868"
            className="d-flex align-contend-center fw-bold fs-4 telephone number"
          >
            <BsFillTelephoneFill className="mt-1 me-1 phoneIcon" />
            +998 90 965 58 68
          </a>
        </div>
        <div className="socialmedia pt-1">
          <div className="d-flex align-content-center justify-content-center w-100">
            <a
              className="social"
              href="https://www.facebook.com/spaintile"
              target="_blank"
            >
              <ImFacebook className=" instagram" />
            </a>
            <a
              className="social"
              href="https://www.instagram.com/mirlar.uz"
              target="_blank"
            >
              <BsInstagram className="instagram" />
            </a>
            <a className="social" href="https://t.me/mirlaruzb" target="_blank">
              <FaTelegramPlane className="instagram" />
            </a>
          </div>
        </div>
      </div>
      <div
        className="header shadow w-100 d-flex align-content-center justify-items-around"
        id="Главний"
      >
        <div className="logodiv">
          <Link to="/">
            <img src={Logo} alt="logo" className="logo" />
          </Link>
        </div>
        <ul
          ref={navbar}
          className="d-flex navbar lefthide align-content-center"
        >
          <li>
            <a href="/#mainpage">Главный</a>
          </li>
          <li className="dropDown">
            <a href="/#product">Товары</a>
            {openDrop ? (
              <RiArrowDropDownLine
                onClick={dropMenu}
                className="fs-2 dropIcon"
              />
            ) : (
              <RiArrowDropUpLine onClick={dropMenu} className="fs-2 dropIcon" />
            )}
            <div ref={drop} className="dropdown-content">
              {categories.length > 0 ? (
                <>
                  {categories.map((arr, id) => {
                    return (
                      <div
                        onClick={() => {
                          dropMenu();
                        }}
                      >
                        <Link to={`/products/${arr.name}`}>
                          <p className="productNavigate">
                            {getShortText(arr.name)}
                          </p>
                        </Link>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </li>

          <li>
            <a href="/#company">О компании</a>
          </li>
          <li className="dropDown">
            <a href="/#brend">Бренды</a>
            {openDropTwo ? (
              <RiArrowDropDownLine
                onClick={dropMenuTwo}
                className="fs-2 dropIcon"
              />
            ) : (
              <RiArrowDropUpLine
                onClick={dropMenuTwo}
                className="fs-2 dropIcon"
              />
            )}
            <div ref={dropTwo} className="dropdown-content">
              {productBrend.length > 0 ? (
                <>
                  {productBrend.map((arr, id) => {
                    return (
                      <div
                        onClick={() => {
                          dropMenuTwo();
                        }}
                      >
                        <Link to={`/products/${arr.brandname}`}>
                          <p className="productNavigate">
                            {getShortText(arr.brandname)}
                          </p>
                        </Link>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </li>
          <li>
            <a href="/#servicess">Сервис</a>
          </li>
          <li>
            <a href="/#located">Наши адреса</a>
          </li>
        </ul>
        <div className="d-flex align-content-center inputs">
          <input
            value={search}
            type="text"
            className="searchinput"
            placeholder="Поиск..."
            onInput={(item) => {
              setSearch(item.target.value);
            }}
            onKeyUp={(e) => {
              if (e.code === "Enter") {
                navigate(`/products/${search}`);
                setSearch("");
              }
            }}
          />
          <button
            className="btn btnsearch me-1"
            onClick={() => {
              navigate(`/products/${search}`);
              setSearch("");
            }}
          >
            <BsSearch className="iconsearch fs-4" />
          </button>
        </div>
        <div>
          {togleMenu ? (
            <RiMenuFoldFill onClick={putBtn} className="fs-1 d-none menubtn" />
          ) : (
            <AiOutlineClose onClick={putBtn} className="fs-1 d-none btnx" />
          )}
        </div>
      </div>
    </div>
  );
}
