import React from "react";
import "./Footer.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { ImFacebook } from "react-icons/im";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Product from "../../services/Api/clien";
import { Link } from "react-router-dom";
import Slide from "react-reveal/Slide";



export default function Footer() {
  const [categorie, setCategorie] = useState([]);

  useEffect(() => {
      Product.getCategories()
        .then((res) => {
          setCategorie([...res.data]);
        })
        .catch((err) => {
        });
  }, []);

  function getShortText(text) {
    if (text.length > 12) return text.slice(0, 13) + "...";
    else return text;
}

  return (
    <Slide bottom>
      <div>
        <div className="py-4 footerbg">
          <div className="container d-flex foterAbout justify-content-between">
            <div className="direction">
              <h5 className="footerTitle">Направления</h5>
              <div className="d-flex flex-column foterText">
                <a href="/#mainpage" className="text-white">
                  <MdOutlineNavigateNext className="footerIcon" />
                  Главный
                </a>

                <a href="/#product" className="text-white">
                  <MdOutlineNavigateNext className="footerIcon" />
                  Товары
                </a>

                <a href="/#company" className="text-white">
                  <MdOutlineNavigateNext className="footerIcon" />О компании
                </a>

                <a href="/#brend" className="text-white">
                  <MdOutlineNavigateNext className="footerIcon" />
                  Бренды
                </a>

                <a href="/#servicess" className="text-white">
                  <MdOutlineNavigateNext className="footerIcon" />
                  Сервис
                </a>

                <a href="/#located" className="text-white">
                  <MdOutlineNavigateNext className="footerIcon" />
                  Наши адреса
                </a>
              </div>
            </div>

            <div className="direction">
              <h5 className="footerTitle text-start">Товары</h5>
              <div className="footerCategory">
                {categorie.map((arr, id) => {
                  return (
                    <Link to={`/products/${arr.name}`}>
                      <p className="text-white">
                        <MdOutlineNavigateNext className="footerIcon" />
                        {getShortText(arr.name)}
                      </p>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="direction">
              <h5 className="footerTitle">Контакты</h5>
              <div>
                <p className="text-white footerPhone">
                  <a href="tel:+998909655868">(90) 965 58 68</a>
                </p>
                <p className="text-white footerPhone">
                  <a href="tel:+998998585666">(99) 858 56 66</a>
                </p>
                <p className="text-white footerPhone">
                  <a href="tel:+998974703936">(97) 470 39 36</a>
                </p>
                <div className="d-flex align-content-center justify-center w-100">
                  <a
                    className="social"
                    href="https://www.facebook.com/spaintile"
                    target="_blank"
                  >
                    <ImFacebook className="fs-5 instagram" />
                  </a>
                  <a
                    className="social"
                    href="https://www.instagram.com/mirlar.uz"
                    target="_blank"
                  >
                    <BsInstagram className="fs-5 instagram" />
                  </a>
                  <a
                    className="social"
                    href="https://t.me/mirlaruzb"
                    target="_blank"
                  >
                    <FaTelegramPlane className="fs-5 instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-dark py-2 d-flex align-content-center justify-content-center">
          <div className="container d-flex justify-content-center align-content-center">
            <p className="text-white m-auto">
              Copyright
              {" " + format(new Date(), "yyyy") + " "}© Mirlar.Uz
            </p>
          </div>
        </div>
      </div>
    </Slide>
  );
}
