import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import "./MainPage.css";
import CaruselSlider from "../Components/Carusel/CaruselSlider";
import Company from "../Components/Company/Company";
import Brend from "../Components/Brend/Brend";
import Servicess from "../Components/Servicess/Servicess";
import Located from "../Components/Located/Located";
import { Link } from "react-router-dom";
import Product from "..//services/Api/clien";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slide from "react-reveal/Slide";

export default function MainPage() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    Product.getCategories()
      .then((res) => {
        setCategories([...res.data]);
      })
      .catch((err) => {});
  }, []);

  return (
    <div id="mainpage" className="mainpage">
      <div className="carusel">
        <CaruselSlider />
      </div>
      <Slide bottom>
        <div className="customers text-center py-5 d-flex row">
          <div className="col-md-6 col-xl-4 col-lg-6 col-sm-12">
            <h1 className="customerNumber">№1</h1>
            <h2 className="fw-bold customerText">в сегменте премиум</h2>
          </div>
          <div className="col-md-6 col-xl-4 col-lg-6 col-sm-12">
            <h1 className="customerNumber">15</h1>
            <h2 className="fw-bold customerText">лет на рынке Узбекистана</h2>
          </div>
          <div className="col-md-6 col-xl-4 col-lg-6 col-sm-12">
            <h1 className="customerNumber">3</h1>
            <h2 className="fw-bold customerText">шоу-рума</h2>
          </div>
        </div>
      </Slide>
      <div style={{ position: "relative", top: "-125px" }} id="product"></div>
      <div className="text-center py-5 bg-light">
        <Slide bottom>
          <h1 className="fs-1 headerTitle">Товары</h1>
          <div className="container">
            <div className="row my-5 justify-content-center">
              {categories.length > 0 ? (
                <>
                  {categories.map((arr, id) => {
                    return (
                      <div className="col-md-6 col-xl-3 col-lg-4 col-sm-12 mb-4 categoryCol">
                        <Link to={`/products/${arr.name}`}>
                          <div className="card categoryCard p-1">
                            <div className="p-3">
                              <LazyLoadImage
                                className="w-100 mainImg"
                                key={id}
                                src={arr.poster}
                                effect="blur"
                                alt="Image Alt"
                              />
                            </div>
                            <div className="p-2">
                              <h2 className="categoryText">{arr.name}</h2>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#FCC12C"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
            </div>
          </div>
        </Slide>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Company />
      <Brend />
      <Servicess />
      <Located />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
