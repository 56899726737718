import React, { useEffect, useState } from "react";
import "./CaruselSlider.css";
import { Carousel } from "antd";
import axios from "axios";

export default function CaruselSlider() {
  const [carusel, setCarusel] = useState([]);

  useEffect(() => {
    axios
      .get("https://backmir.mirlar.uz/slider")
      .then((res) => {
        setCarusel([...res.data]);
      })
      .catch((err) => {
      });
  }, []);

  const contentStylee = {
    height: "800px",
    width: "100%",
    color: "#fff",
    lineHeight: "260px",
    textAlign: "center",
  };
  return (
    <div className="mt-0">
      <Carousel autoplay>
            {carusel.map((arr, id) => {
              return (
                <div className="caruseldiv">
                  <img
                    className="caruselImg"
                    style={contentStylee}
                    src={arr.poster}
                    alt=""
                  />
                  <div
                    className="caruseltext fw-bold"
                    dangerouslySetInnerHTML={{ __html: `<h1>${arr.text}</h1>` }}
                  ></div>
                </div>
              );
            })}
      </Carousel>
    </div>
  );
}
