import React from 'react';
// import { Parallax, Background } from "react-parallax";
import { Parallax } from "react-parallax";
import './Servicess.css'
import ServicImg from "../../img/servisImg.jpeg";
import Slide from "react-reveal/Slide";


export default function Servicess() {

  return (
    <Slide bottom>
      <div style={{ position: "relative", top: "-175px" }} id="servicess"></div>
      <div className="text-center serviCess">
        <h1 className="headerTitle mb-5">Сервис</h1>
        <div>
          <Parallax
            blur={{ min: -15, max: 20 }}
            bgImage={ServicImg}
            bgImageAlt="the cat"
            strength={200}
          >
            <div className="parallax">
              <div className="parallaxText">
                <h2 className="fw-bold mb-3">Бесплатная консультация</h2>
                <h3 className="text-center text-white">
                  Поможем с выбором более подходящего вам дизайна. Дайте нам
                  размеры и мы бесплатно посчитаем квадратуру вашей комнаты.
                </h3>
              </div>
            </div>
          </Parallax>
          <Parallax
            blur={{ min: -15, max: 17 }}
            bgImage="https://evo.business/wp-content/uploads/2016/06/Depositphotos_30415293_m-2015.jpg"
            bgImageAlt="modern"
            strength={200}
          >
            <div className="parallax">
              <div className="parallaxText">
                <h2 className="fw-bold mb-3">Доставка</h2>
                <h3 className=" text-center text-white">
                  Бесплатная доставка по городу Ташкент.
                </h3>
              </div>
            </div>
          </Parallax>
        </div>
      </div>
    </Slide>
  );
}
