import React from "react";
import "./Company.css";
import Typography from "@mui/material/Typography";
import ispanImg from "..//../img/ispanImg.jpg";
import imgIaly from "..//../img/imgItaly.jpg";
import imgIaly1 from "..//../img/imgItaly1.jpg";
import imgIaly2 from "..//../img/imgItaly2.jpg";
import shop1 from "..//../img/shop1.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Company() {


  return (
    <section>
      <div style={{ position: "relative", top: "-125px" }} id="company"></div>
      <div className="container text-center py-5">
        <h1 className="headerTitle mb-5">О компании</h1>
        <div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4">
              <div>
                <LazyLoadImage
                  className="rounded w-100"
                  src={shop1}
                  effect="blur"
                  alt="Image Alt"
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4">
              <div>
                <Typography>
                  <p className="text-start textCompany">
                    <b>MIRLAR</b> является официальным дистрибьютером ведущих
                    Испанских, Италянских и Индийских брендов по производству
                    керамической плитки, керамогранита и майолики (мозаики), что
                    дает потребителю постоянно актуальный доступ к новинкам и
                    самым актуальным ценам на рынке.
                  </p>
                </Typography>

                <Typography>
                  <p className="text-start textCompany">
                    Фирменный бренд <b>MIRLAR</b> под названием шоурумов
                    ISPANIYA KAFELLARI предлагает сотрудничество на выгодных
                    условиях дизайнерам, архитекторам, мастерам, сантехникам и
                    прорабам.
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <h2 className="companyText fw-bold mb-5">Испанские плитки</h2>
          <div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 infoOrder">
                <div>
                  <p className="text-start textCompany">
                    С давних пор Испания считается одним из старейших и
                    важнейших поставщиков кафельной плитки на территории
                    Европейских стран и это не удивительно, ведь по данным
                    историков, именно испанская плитка стала первой керамической
                    плиткой в мире. Да и на современном этапе развития мира
                    плитку испанских производителей до сих пор активно
                    используют по всему миру. Смело можно сказать, что доля этой
                    страны по предложениям кафельной плитки на современном
                    строительном рынке колеблется от 38% до 42%. Испанский
                    кафель популярен и на территории нашей страны, что говорит о
                    его хорошем качестве. <br />
                    <br />
                    Испания имеет многовековые традиции по изготовлению плитки.
                    В стране работает несколько всемирно известных предприятий и
                    дизайнерские студии, разрабатывающие идеи оформления
                    отделочного материала. В наших шоурумах представлены самые
                    разнообразные плитки Испанского производства, Обладающий
                    целым рядом преимуществ: Повышенная устойчивость к
                    механическим повреждениям, ударопрочность, стойкость к
                    воздействию воды, подходит для применения в помещениях с
                    высокой влажностью, устойчивость к низким температурам,
                    экологическая безопасность, при производстве используются
                    исключительно натуральные компоненты, огнестойкость,
                    устойчивость к воздействию бытовой химии, эстетичность,
                    продуманный дизайн, долговечность.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <LazyLoadImage
                  className="infoCounrtImg rounded w-100 infoOrder"
                  src={ispanImg}
                  effect="blur"
                  alt="Image Alt"
                />
              </div>
            </div>
            <h2 className="companyText fw-bold mb-5">
              Италянские широкоформатные плитки
            </h2>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-5">
                <div>
                  <LazyLoadImage
                    className="infoCounrtImg rounded w-100"
                    src={imgIaly}
                    effect="blur"
                    alt="Image Alt"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-5">
                <div>
                  <p className="text-start textCompany">
                    Сегодня почти все производители плитки имеют в ассортименте
                    коллекции с широкоформатным керамогранитом и регулярно
                    предлагают удивительные дизайны. Архитекторы и дизайнеры
                    восхищаются крупногабаритной плиткой через ее реалистичную
                    фактуру, удобство использования и свойства. Для того, чтобы
                    обеспечить не только эстетический вид, но и прочность и
                    износостойкость, производители используют специальные
                    технологии изготовления широких форматов плитки.
                    <br />
                    <br />
                    Благодаря таким технологиям, крупноформатная плитка не
                    только эстетически привлекательна, но и обладает высокой
                    прочностью и долговечностью, что делает ее идеальным
                    решением для использования как в жилых, так и коммерческих
                    помещениях. Большие форматы плитки удачно применяются во
                    многих дизайнерских проектах и становятся идеальным выбором
                    для тех, кто ищет современные и стильные решения для
                    оформления дизайнов помещений.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 infoOrder">
                <div>
                  <p className="text-start textCompany">
                    <b>
                      Преимуществами широкоформатного керамогранита являются:
                    </b>
                    <br />
                    <b>Эффект непрерывности.</b> Широкоформатный керамогранит
                    открывает безграничные возможности для дизайна интерьера и
                    экстерьера. Его большой формат позволяет создать стильный
                    вид, который привлекает внимание и визуально расширяет
                    пространство. Большие плиты уменьшают количество швов и
                    создают монолитный вид, что добавляет элегантности и
                    современности в любое помещение.
                    <br />
                    <br />
                    <b>Разнообразие использования.</b> Большие форматы - больше
                    инновационных идей по дизайну, поскольку они дают доступ к
                    широкому списку применений благодаря своим размерам и
                    высоким техническим характеристикам. Широкоформатный
                    керамогранит может быть установлен на полу, настенных
                    панелях, при отделке фасадов зданий, террас и бассейнов. Это
                    делает его универсальным материалом, который можно
                    использовать как во внутренней, так и во внешней отделке.
                    <br />
                    <br />
                    <b>Экономность.</b> Большие плиты позволяют покрывать
                    поверхности, используя небольшое количество плит. Кроме
                    того, поскольку детали имеют выпрямленные соединения,
                    расстояние между листами значительно уменьшается. Это
                    позволяет тратить меньше бюджета на закупку плитки, ведь
                    большие форматы выгоднее стандартных.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4">
                <div>
                  <LazyLoadImage
                    className="infoCounrtImg rounded w-100 infoOrder"
                    src={imgIaly1}
                    effect="blur"
                    alt="Image Alt"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4">
                <div>
                  <LazyLoadImage
                    className="infoCounrtImg rounded w-100"
                    src={imgIaly2}
                    effect="blur"
                    alt="Image Alt"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4">
                <div>
                  <p className="text-start textCompany">
                    <b>Время установки.</b> Из-за своих больших размеров,
                    широкоформатный керамогранит требует меньшего количества
                    плитки для покрытия поверхности. Это способствует снижению
                    количества времени, необходимого для укладки, поскольку
                    уменьшается количество разрезов и соединительных швов.
                    Быстрая установка также позволяет уменьшить затраты на
                    рабочую силу и ускорить процесс строительства.
                    <br />
                    <br />
                    <b>Трендовость.</b> Использование широкоформатного
                    керамогранита сейчас актуально как никогда. Каждый уважающий
                    себя дизайнер или архитектор использует в своих проектах
                    большие форматы плитки для отделки стен или пола.
                    Популярность прежде всего характеризуется удобством и
                    скоростью укладки плит.
                  </p>
                </div>
              </div>
            </div>
            <h2 className="companyText fw-bold mb-5">
              Индийские керамограниты
            </h2>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 infoOrder">
                <div>
                  <p className="text-start textCompany">
                    Среди относительных новинок на узбекском рынке отделочных
                    материалов выделяется индийский керамогранит, купить который
                    можно в Ташкенте на выгодных условиях. Высококачественная
                    керамогранитная плитка находит применение в облицовке стен и
                    пола в помещениях различного назначения, а также для
                    наружного оформления зданий. Фасад, для отделки которого
                    использован керамогранит 60x120, смотрится особенно
                    эффектно.
                    <br />
                    <br />
                    Преимущества индийского керамогранита Использование
                    современного оборудования при производстве позволяет
                    создавать керамогранит для любого помешения. Несомненные
                    достоинства делают индийский керамогранит выгодным
                    приобретением для ремонтно-строительных компаний, частных
                    лиц.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4">
                <div>
                  <LazyLoadImage
                    className="infoCounrtImg rounded w-100 infoOrder"
                    src="https://www.keramogranit.ru/upload/resize_cache/iblock/3f3/372_305_1/vintage.jpg"
                    effect="blur"
                    alt="Image Alt"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
