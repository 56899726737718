import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Slide from "react-reveal/Slide";



import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Brend.css";

// import required modules
import  axios from "axios";

export default function Brend() {
  const [brendCarusel, setbrendCarusel] = useState([]);

  useEffect(() => {
    axios.get("https://backmir.mirlar.uz/brand/")
      .then((res) => {
        setbrendCarusel([...res.data]);
      })
      .catch((err) => {
      });
  }, []);


  return (
    <Slide bottom>
      <div style={{ position: "relative", top: "-125px" }} id="brend"></div>
      <div className="text-center py-5 bg-light my-5">
        <h1 className="headerTitle mb-4">Бренды</h1>
        <div>
          <div className="slider">
            {brendCarusel.map((arr, id) => {
              return (
                <div className="slide-track">
                    <div className="slide">
                      <img key={id} src={arr.brandlogo} alt="" />
                    </div>
                </div>
              );
            })}
            {brendCarusel.map((arr, id) => {
              return (
                <div className="slide-track">
                  <div className="slide">
                    <img key={id} src={arr.brandlogo} alt="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Slide>
  );
}
